import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Parallax } from "react-parallax";
import { IMAGES } from "../helper/images.js";
import { BASE_URL } from "../helper/base_url.js";
import { GetAllImagesApi } from "../helper/http_api.js";
import { useLocation } from "react-router-dom";

// home components
import HomeSectionTop from "../components/homecomponents/home_section_top.js";
import HomeSectionMiddle from "../components/homecomponents/home_section_middle.js";
import HomeSectionBottom from "../components/homecomponents/home_section_bottom.js";

// styles
import styles from "../styles/home.module.css";

function Home() {
  // states
  const [isMuted, setIsMuted] = useState(true);
  const [videoUrl, setVideoUrl] = useState("");
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const useLocation_Data = useLocation();

  const home_section_top_ref = useRef(null);
  const home_section_middle_ref = useRef(null);
  const home_section_bottom_ref = useRef(null);

  const isMobileView = () => window.matchMedia("(max-width: 767px)").matches;
  useEffect(() => {
    if (useLocation_Data.pathname == "/Contact_Us") {
      // scrollToHomeSectionBottom();
      if (isMobileView()) {
        // console.error("mobile view", document.documentElement.scrollHeight);
        window.scrollTo({
          top: document.documentElement.scrollHeight - 800,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, [useLocation_Data.pathname]);

  /* optimised code for getting media data from server */
  const getAllImages = async () => {
    try {
      const response = await GetAllImagesApi();
      const { status, All_Files } = response.data;

      if (status === "success") {
        const Section1_files = All_Files.Section1_files || [];
        setVideoUrl(Section1_files[0]?.image_url || "");
        setIsVideoLoaded(true);
      } else {
        setIsVideoLoaded(false);
      }
    } catch (error) {
      console.log("Error occurred while fetching video from server:", error);
      setIsVideoLoaded(false);
    }
  };

  useLayoutEffect(() => {
    getAllImages();
  }, []);

  /* auto scroll from home_section_bottom to home_section_top */
  const scrollToHomeSectionTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /* auto scroll from home_section_top to home_section_middle */
  const scrollToHomeSectionMiddle = () => {
    home_section_middle_ref.current.scrollIntoView({ behavior: "smooth" });
  };

  /* auto scroll from home_section_middle to home_section_bottom */
  const scrollToHomeSectionBottom = () => {
    home_section_bottom_ref.current.scrollIntoView({
      block: "nearest",
      behavior: "smooth",
    });
  };

  /* video mute/unmute handler */
  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  // console.log("Video loaded ===> ", isVideoLoaded);

  return (
    <div>
      {/* home landing page */}
      <div className={styles.main}>
        {/* if video loaded from server show video bg */}
        {isVideoLoaded && (
          <video
            playsInline
            crossOrigin="anonymous"
            className={styles.video_intro}
            src={`${BASE_URL}/sengal_admin/download_vidFile/Section1_adminFiles${videoUrl}`}
            autoPlay
            loop
            muted={isMuted}
            fluid={false}
          />
        )}
        {/* if no video load from server show bg img */}
        {isVideoLoaded === false && (
          <img src={IMAGES.bgImg_section1} className={styles.optional_img} />
        )}
        <div className={styles.content} ref={home_section_top_ref}>
          <div className={styles.overlay}>
            <HomeSectionTop
              scrollToHomeSectionMiddle={scrollToHomeSectionMiddle}
              onToggleMute={toggleMute}
            />
          </div>
        </div>
      </div>
      {/* home slider images section */}
      <Parallax
        bgImage={IMAGES.bgImg_section2}
        bgImageAlt=""
        strength={180}
        id="home_section_middle"
      >
        <div className={styles.content} ref={home_section_middle_ref}>
          <HomeSectionMiddle
            scrollToHomeSectionBottom={scrollToHomeSectionBottom}
          />
        </div>
      </Parallax>
      {/* home room portfolio */}
      <Parallax
        bgImage={IMAGES.bgImg_section3}
        bgImageAlt=""
        strength={180}
        id="home_section_bottom"
      >
        <div className={styles.content} ref={home_section_bottom_ref}>
          <HomeSectionBottom scrollToHomeSectionTop={scrollToHomeSectionTop} />
        </div>
      </Parallax>
    </div>
  );
}

export default Home;

import React, { useState, useEffect } from "react";
import styles from "../../styles/terms_condition.module.css";
import Cancel_Svg from "../../assets/svg/cancel_svg";
import { STRINGS, TOOLBARCONFIG } from "../../model/constant";
import RichTextEditor, {
  getTextAlignClassName,
  getTextAlignStyles,
  getTextAlignBlockMetadata,
} from "react-rte";

const TermsConditions = ({ closeTermsPopup, isContent_Data }) => {
  const openInNewTab = (event) => {
    const target = event.target;
    if (event.target.closest("a")) {
      // Check if the clicked element is a link
      event.preventDefault(); // Prevent the default link behavior
      window.open(target.closest("a").href, "_blank"); // Open the link in a new tab
    }
  };
  return (
    <div className={styles.termsOpacityPopup}>
      <div className={styles.termsPopup}>
        <div className={styles.termsHeader}>
          <div className={styles.centerView}>
            <div className={styles.termsheading}>{STRINGS.terms_value}</div>
          </div>
          <div className={styles.iconview}>
            <div
              className={styles.closeButton}
              onClick={closeTermsPopup}
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key == "Enter") {
                  closeTermsPopup();
                }
              }}
            >
              <Cancel_Svg />
            </div>
          </div>
        </div>
        <div onClick={openInNewTab} className={styles.termsBody}>
          {/* <div dangerouslySetInnerHTML={{ __html: isContent_Data }} /> */}
          <RichTextEditor
            toolbarConfig={TOOLBARCONFIG}
            blockStyleFn={getTextAlignClassName}
            value={isContent_Data}
            // placeholder={STRINGS.content_placeholder}
            toolbarStyle={{
              backgroundColor: "transparent",
              border: "none",
            }}
            rootStyle={{
              backgroundColor: "transparent",
              border: "none",
            }}
            editorStyle={{
              display: "flex",
              backgroundColor: "transparent",
              overflow: "hidden",
            }}
            readOnly={true}
          />
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;

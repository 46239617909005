import React, { useState, useEffect, useRef } from "react";
import {
  storeBookingDetailsApi,
  couponCodeApi,
  removeCouponApi,
  get_Discount_Availablity_Status,
  get_Policy_Status,
} from "../helper/http_api";
import { BsCheck2All } from "react-icons/bs";
// import { useLocation } from "react-router-dom";
import { IMAGES } from "../helper/images";
import { Parallax } from "react-parallax";
import { SpinnerDotted } from "spinners-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import TermsConditions from "../components/commoncomponents/TermsConditions";

//import{Cancel} from "../@mui/icons-material";
import {
  setBookFunc,
  setPaymentSummaryDataFunc,
  getBookingSummaryDataFunc,
} from "../helper/temporary_data";
import { BASE_URL } from "../helper/base_url";
import axios from "axios";
import { config } from "../configs/config";
// import Dropdown from "react-dropdown";
import HeaderWithIcon from "../components/commoncomponents/header_home_icon";

import book_hotel_img from "../assets/img/order_summary_hotel_img.jpg";
import promoCode_logo from "../assets/img/promo-code_img.png";
import promoCode_logo1 from "../assets/img/promo-code_img1.png";
import styles from "../styles/booking_details.module.css";
import styles1 from "../styles/payment_checkout.module.css";
import { POLICY_CODES_LIST, STRINGS } from "../model/constant";
import RichTextEditor, {
  getTextAlignClassName,
  getTextAlignStyles,
  getTextAlignBlockMetadata,
} from "react-rte";

function BookingDetails() {
  const [Booking_record_stored, setBooking_record_stored] = useState(false);
  const [Booking_id, setBooking_id] = useState("");

  const [paid, setPaid] = useState(false);

  // complimentry breakfast state
  const [isComplimentry, setIsComplimentry] = useState(true);
  const InputRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [isCouponApplied, setCouponApplied] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState({
    state: false,
    isContent_Data: RichTextEditor.createEmptyValue(),
  });
  const [finalAveragePerDay,setFinalAveragePerDayRent] =useState();
  const  [total,setTotal]=useState();
  // let data = {};
  /* data coming from pojo */
  // const getBookingDataHere = async () => {
  let data = getBookingSummaryDataFunc();
  data = JSON.parse(data);
  // };
  {
    /*const [discountList,setDiscountList]=useState({ name: 'SBH_DISCOUNT1', content: 'MEGA OFFER', percentage: '30', from: '20 JUN 2024', to: '31 JUL 2024'})
 const [isDisscountApplied,setIsDiscountApplied]=useState(false);
 const discountFromDate = new Date(discountList.from);
 const discountToDate = new Date(discountList.to);
 const currentDate = new Date();

 */
  }
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };
  const openTermsPopup = () => {
    getPolicy_Content(POLICY_CODES_LIST.TERMS_AND_CONDITION);
  };

  /**
   * @param null
   * @description Get current content of the policy of terms and condition
   * @returns null
   */
  const getPolicy_Content = async (Policy_Code) => {
    try {
      setShowSpinner(true);
      const response = await get_Policy_Status(Policy_Code);
      if (response.success) {
        setShowTermsPopup({
          state: true,
          isContent_Data: RichTextEditor.createValueFromString(
            response?.responseContent?.policy_Content,
            "html",
            {
              customBlockFn: getTextAlignBlockMetadata,
            }
          ),
        });
      } else {
        alert.error(STRINGS.somethingunexpected);
      }
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      alert.error(STRINGS.somethingunexpected);
      console.log("Error in check policy status");
    }
  };
  const closeTermsPopup = () => {
    setShowTermsPopup((prev) => ({ ...prev, state: false }));
  };
  // getBookingDataHere();
  const checkInDate = data.checkInDate;
  const checkOutDate = data.checkOutDate;
  const numberOfRooms = data.numberOfRooms;
  const numberOfExtraBeds = data.numberOfExtraBeds;
  const numberOfAdults = data.numberOfAdults;
  const numberOfKids = data.numberOfKids;
  const RoomType = data.RoomType;
  const PerDayCost = data.PerDayCost;
  const SavingsCost= data.SavingsCost;
  const discountedPrice = data.discountedPrice
  const ExtraBedCost = data.ExtraBedCost;
  const AfterDiscountedPrice = data.AfterDiscountedPrice;
  const discountDescriptions = data.DiscountDescriptions;
  const [discount_Details, setDiscount_Details] = useState(null);
  // setDiscount_Details({
  // discount_description: data?.DiscountDescriptions,
  //  })
  // const formattedDiscounts = discountDescriptions.length
  // ? `Available discounts: ${discountDescriptions
  //     .map((desc) => desc?.charAt(0).toUpperCase() + desc?.slice(1)) // Capitalize first letter
  //     .join(" and ")}`
  // : "";
  // const hasDiscounts = Array.isArray(discountDescriptions) && discountDescriptions.length > 0;
  // console.log(discountDescriptions)
  const GST = data.GST; 
  const [pricePerDay, setPricePerDay] = useState(PerDayCost);

  const loggedInUserToken =
    localStorage.getItem("loggedInUserToken") != null &&
    localStorage.getItem("loggedInUserToken") != "undefined"
      ? JSON.parse(localStorage.getItem("loggedInUserToken"))
      : "";

  const loggedInUserId =
    localStorage.getItem("loggedInUserId") != null &&
    localStorage.getItem("loggedInUserId") != "undefined"
      ? JSON.parse(localStorage.getItem("loggedInUserId"))
      : "";
  const loggedIn_user_name =
    localStorage.getItem("loggedIn_user_name") != null &&
    localStorage.getItem("loggedIn_user_name") != "undefined"
      ? JSON.parse(localStorage.getItem("loggedIn_user_name"))
      : "";
  const loggedIn_user_phone =
    localStorage.getItem("loggedIn_user_phone") != null &&
    localStorage.getItem("loggedIn_user_phone") != "undefined"
      ? JSON.parse(localStorage.getItem("loggedIn_user_phone"))
      : "";
  const loggedIn_user_email =
    localStorage.getItem("loggedIn_user_email") != null &&
    localStorage.getItem("loggedIn_user_email") != "undefined"
      ? JSON.parse(localStorage.getItem("loggedIn_user_email"))
      : "";
  const loggedIn_user_mail_status =
    localStorage.getItem("loggedIn_user_mail_status") != null &&
    localStorage.getItem("loggedIn_user_mail_status") != "undefined"
      ? JSON.parse(localStorage.getItem("loggedIn_user_mail_status"))
      : "";

  const [showSpinner, setShowSpinner] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // const [extraBedOptions, setextraBedOptions] = useState([]);

  const [guestData, setGuestData] = useState({
    guestName: loggedIn_user_name,
    guestMobile: loggedIn_user_phone,
    guestEmail: loggedIn_user_email,
    // numberOfExtraBeds: "",
  });

  useEffect(() => {
    // let n_extraBed = numberOfRooms * 1;
    // let extraBedOptions1 = [];

    // for (let i = 0; i <= n_extraBed; i++) {
    //   extraBedOptions1.push({ value: i.toString(), label: i.toString() });
    // }

    // setextraBedOptions(extraBedOptions1);

    // setBooksummary(data);
    // getDiscount_Status();
    const storedCouponDetails = JSON.parse(localStorage.getItem("couponDetails"));
    if (storedCouponDetails && storedCouponDetails.isCouponApplied) {
      setCouponApplied(true);
      setFinalAveragePerDayRent(storedCouponDetails.finalAveragePerDayRent);
      setSaving(storedCouponDetails.savings);
      setTotal(storedCouponDetails.total);
      setDiscount(storedCouponDetails.couponPercentage);
      setCouponData((prevData) => ({
        ...prevData,
        couponCode: storedCouponDetails.couponCode,
      }));
    } else {
      setCouponApplied(false); // Reset to default
    }
  
    setIsButtonDisabled(false); // Ensure buttons are enabled on page load
    const handleResize = () => {
      if (window.innerWidth <= 912) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }

      // Check for landscape orientation with a max-width of 767 pixels
      const isLandscapeOrientation = window.matchMedia(
        "(max-height: 575.98px) and (orientation: landscape)"
      ).matches;
      setIsLandscape(isLandscapeOrientation);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const alert = useAlert();

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    /* custom validity for name */
    if (name === "guestName") {
      const namePattern = /^[a-zA-Z ]+$/;
      if (!namePattern.test(value)) {
        e.target.setCustomValidity("Please enter alphabetic characters only.");
      } else if (value.length < 3) {
        e.target.setCustomValidity("Name shouldn't be less than 3 characters.");
      } else if (value.length > 26) {
        e.target.setCustomValidity(
          "Name shouldn't be more than 26 characters."
        );
      } else {
        e.target.setCustomValidity("");
      }

      if (value.length === 0) {
        e.target.setCustomValidity("Name is a required field.");
      }
    }

    if (name === "guestMobile") {
      const isAllZeros = /^\d+$/.test(value) && parseInt(value, 10) === 0;
      const startsWithMultipleZeros = /^0{2,}/.test(value);
      const hasRepeatingDigits =
        /(0{6,}|1{6,}|2{6,}|3{6,}|4{6,}|5{6,}|6{6,}|7{6,}|8{6,}|9{6,})/.test(
          value
        );

      if (value.length < 10) {
        e.target.setCustomValidity("Phone number must be 10 digits long.");
      } else if (isAllZeros) {
        e.target.setCustomValidity("Invalid phone number.");
      } else if (startsWithMultipleZeros) {
        e.target.setCustomValidity("Invalid phone number.");
      } else if (hasRepeatingDigits) {
        e.target.setCustomValidity("Invalid phone number.");
      } else {
        e.target.setCustomValidity("");
      }
    }

    /* custom validity for email */
    if (name === "guestEmail") {
      const emailPattern = /^[a-z0-9._%+]+@[a-z0-9.]+\.[a-z]{2,}$/i;
      if (!emailPattern.test(value)) {
        e.target.setCustomValidity("Please enter a valid email Id.");
      } else {
        e.target.setCustomValidity("");
      }
    }

    setGuestData((prevGuestData) => ({
      ...prevGuestData,
      [name]: value,
    }));
  };

  // handle complimentry change
  const handleComplimentryChange = () => {
    setIsComplimentry(!isComplimentry);
  };

  const check_in_date = new Date(checkInDate);
  const check_out_date = new Date(checkOutDate);

  const timeDifference = check_out_date - check_in_date;
  const daysGap = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  //let [pricePerDay,setPricePerDay]=useState(PerDayCost)

  // console.log(
  //   `ExtraBedCost = ${ExtraBedCost}, numberOfExtraBeds = ${numberOfExtraBeds}, daysGap = ${daysGap}`
  // );
  // let priceExtraBed = ExtraBedCost * numberOfExtraBeds * daysGap;
  let priceExtraBed = ExtraBedCost * numberOfExtraBeds * daysGap;
  let pricePerExtraBed = 0;
  if (numberOfExtraBeds) {
    pricePerExtraBed = priceExtraBed / (numberOfExtraBeds * daysGap);
  } else {
    pricePerExtraBed = priceExtraBed / (1 * daysGap);
  }

  let totalCost1 = numberOfRooms * daysGap * pricePerDay + priceExtraBed;
  /* gst calculation for */
  // let totalCost2 = pricePerDay + pricePerExtraBed;
  let subTotal = totalCost1;
  let totalCost2 = subTotal / (numberOfRooms * daysGap);
  // console.log("GST Calculation price before discount ==== >", totalCost2);
  // let subTotal = totalCost1 - priceExtraBed;
  let discount1 = 0;
  let gst = GST;
  let gstAmount = 0;
  let gstAmountCoupon = 0
  const discountListpercentage = 20;
  const MINGST = GST.minGstPercentage;
  const MAXGST = GST.maxGstPercentage;
  const gstPrice = GST.GstThresoldPrice;
  let extraBedCost = 0;
  let extraBedCoupon=0;
  if(numberOfExtraBeds===0){
    extraBedCost = AfterDiscountedPrice;
  }else{
    extraBedCost = AfterDiscountedPrice + priceExtraBed;
  }
  if(numberOfExtraBeds===0){
    extraBedCoupon = total
  }else{
    extraBedCoupon = total + priceExtraBed;
  }
  console.log("sdsddadasdasdasdd=>",GST);

  if (extraBedCost <= gstPrice) {
    gstAmount = Math.floor(extraBedCost * (MINGST / 100));
  } else {
    gstAmount = Math.floor(extraBedCost * (MAXGST / 100));
  }
  
  // Calculate GST for total (e.g., with coupon)
  if (extraBedCoupon <= gstPrice) {
    gstAmountCoupon = Math.floor(extraBedCoupon * (MINGST / 100));
  } else {
    gstAmountCoupon = Math.floor(extraBedCoupon * (MAXGST / 100));
  }
  // GST calculation

  // if (totalCost2 <= GST.GstThresoldPrice) {
  //   gstAmount = Math.floor(AfterDiscountedPrice * (GST.minGstPercentage / 100));
  //   // totalCost1 = Math.floor(totalCost1 + gstAmount);
  // } else if (totalCost2 > GST.GstThresoldPrice) {
  //   gstAmount = Math.floor(AfterDiscountedPrice * (GST.maxGstPercentage / 100));
  //   // totalCost1 = Math.floor(totalCost1 + gstAmount);
  // }

  // if (totalCost2 <= GST.GstThresoldPrice) {
  //   gstAmountCoupon = Math.floor(total * (GST.minGstPercentage / 100));
  //   // totalCost1 = Math.floor(totalCost1 + gstAmount);
  // } else if (totalCost2 > GST.GstThresoldPrice) {
  //   gstAmountCoupon = Math.floor(total * (GST.maxGstPercentage / 100));
  //   // totalCost1 = Math.floor(totalCost1 + gstAmount);
  // }

  // console.log("Total const without gst ====>", totalCost2);
  // console.log("GST AMOUNT ====>", gstAmount);
  // console.log("Total Cost with gst ====>", totalCost1);

  // let totalCost2 = totalCost1;
  // let saving = 0;

  const [totalCost, setTotalCost] = useState(totalCost1);

  /* gst calculation */
  let totalCost3 = totalCost / (numberOfRooms * daysGap);

  if (isCouponApplied) {
    /* gst calculation after discount */
    if (totalCost3 <= GST.GstThresoldPrice) {
      gstAmount = Math.floor(totalCost * (GST.minGstPercentage / 100));
      // totalCost1 = Math.floor(totalCost1 + gstAmount);
    } else if (totalCost3 > GST.GstThresoldPrice) {
      gstAmount = Math.floor(totalCost * (GST.maxGstPercentage / 100));
      // totalCost1 = Math.floor(totalCost1 + gstAmount);
    }
  }

  let final_cost = totalCost + gstAmount;

  // console.log("Total cost @@@ 1111 ==> ", totalCost);
  // console.log("Final cost @@@ ==> ", final_cost);
  const [discount, setDiscount] = useState(discount1);
  const [saving, setSaving] = useState(0);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const book_api_payload = {
    // totalCost: totalCost,
    totalCost: !isCouponApplied ? extraBedCost + gstAmount : extraBedCoupon + gstAmountCoupon,
    NofRooms: numberOfRooms,
    NofAdults: numberOfAdults,
    NofChild: numberOfKids,
    guestName: guestData?.guestName
      ? guestData.guestName.trim()
      : guestData.guestName,
    guestEmail: guestData.guestEmail,
    guestMobile: guestData.guestMobile,
    checkInDate: checkInDate,
    checkOutDate: checkOutDate,
    RoomType: RoomType,
    userId: loggedInUserId ? loggedInUserId : "",
    access_token: loggedInUserToken ? loggedInUserToken : "",
    // NofBeds: NoOfBeds ? NoOfBeds : 1,
    NofExtraBeds: numberOfExtraBeds,
    ExtraBedsCost: priceExtraBed,
    amountSave: !isCouponApplied ? SavingsCost : saving,
    fullPayment: !isCouponApplied ? extraBedCost + gstAmount : extraBedCoupon + gstAmountCoupon,
    fullOrPartialPayment: true,
    isComp: isComplimentry,
    // RoomId: RoomId,
  };

  console.log("Booking api data", book_api_payload);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    // disabling button
    setIsButtonDisabled(true);
    if (!termsChecked) {
      setShowSpinner(false);
      alert.error(STRINGS.accept_terms_required);
      // enabling button
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 4000);
    } else if (!loggedIn_user_mail_status) {
      setShowSpinner(false);
      alert.error(STRINGS.signin_signup_required);
      // enabling button
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 4000);
    } else {
      if (
        !book_api_payload.guestName &&
        !book_api_payload.guestEmail &&
        !book_api_payload.guestMobile
      ) {
        alert.success("Please enter required data to continue");
        setShowSpinner(false);
      } else {
        const bookNowPromise = new Promise(async (resolve, reject) => {
          try {
            // console.error(
            //   "total costt===>",
            //   book_api_payload.totalCost,
            //   totalCost
            // );
            const response = await storeBookingDetailsApi(book_api_payload);
            console.log("bookNow api response data : ", response.data);
            resolve(response.data);
          } catch (error) {
            reject(error);
          }
        });

        bookNowPromise
          .then((data) => {
            const { success } = data;
            // setShowSpinner(false);

            setTimeout(() => {
              setShowSpinner(false);
              if (success) {
                /* storing payment summary page data in pojo */
                setPaymentSummaryDataFunc({
                  name: guestData.guestName,
                  emailId: guestData.guestEmail,
                  phone: guestData.guestMobile,
                  ciDate: checkInDate,
                  coDate: checkOutDate,
                  noOfRooms: numberOfRooms,
                  noOfExtraBed: numberOfExtraBeds,
                  adults: numberOfAdults,
                  kids: numberOfKids,
                  subTotal: totalCost1,
                  totalAmount: final_cost,
                  daysGap: daysGap,
                  pricePerDay: pricePerDay,
                  priceExtraBed: priceExtraBed,
                  discountPercentage: discount,
                  couponCode: couponData.couponCode,
                  GST: GST,
                  gstAmount: gstAmount,
                  savingAmount: saving,
                  Booking_id: data?.bookNow?.BookingId,
                });

                // navigate(`/user/payment/checkout`);
                // console.log("BookingId ===> ", data?.bookNow?.BookingId);
                setBooking_id(data?.bookNow?.BookingId);

                setBooking_record_stored(true);
                // hotelPaymentDetails();
              } else {
                alert.error("Something went wrong, please try again later");
              }
              // enabling button
              setTimeout(() => {
                setIsButtonDisabled(false);
              }, 4000);
            }, 1000);
          })
          .catch((error) => {
            setShowSpinner(false);
            console.error(
              "Error occurred while updating booking data into server",
              error
            );
            alert.error("Oops! Something unexpected happened.");
            // enabling button
            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 4000);
          });
      }
    }
  };

  const handleBookingCancel = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    alert.success("Booking request canceled.");
    localStorage.removeItem("couponDetails");
    couponRemovedAPI(); // remove coupon api call
    setTimeout(() => {
      setShowSpinner(false);
      setBookFunc(false);
      navigate(`/`);
    }, 1000);
  };

  const handleHomeClick = () => {
    setShowSpinner(true);
    couponRemovedAPI(); // coupon remove api call
    setTimeout(() => {
      setShowSpinner(false);
      setBookFunc(false);
      navigate(`/`);
    }, 1000);
  };

  const couponCodeInputRef = useRef(null);

  // const [isCouponApplied, setCouponApplied] = useState(false);

  const [couponData, setCouponData] = useState({
    couponCode: "",
  });

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setCouponData((prevcouponData) => ({
      ...prevcouponData,
      [name]: value.toUpperCase(),
    }));
  };

  const handleResetForm = () => {
    setCouponData({
      couponCode: "",
    });
  };

  const coupon_api_payload = {
    couponCode: couponData.couponCode,
    checkInDate: checkInDate,
    checkOutDate: checkOutDate,
    numberOfRooms: numberOfRooms,
    NofAdults: numberOfAdults,
    NofChild: numberOfKids,
    RoomType: RoomType,
  };

  // console.log("Coupon API payload: " + coupon_api_payload);
  const resetCouponState = () => {
    setCouponApplied(false);
    setFinalAveragePerDayRent(null);
    setSaving(null);
    setDiscount(null);
    setTotal(null);
    localStorage.removeItem('couponDetails');
  };
  const handleSubmit1 = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    // disabling button
    setIsButtonDisabled(true);

    if (!loggedIn_user_mail_status) {
      setShowSpinner(false);
      alert.error("Please sign in or sign up to continue.");
      // enabling button
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 4000);
    } else if (!coupon_api_payload.couponCode) {
      alert.success("Please enter a valid coupon code before applying.");
      setShowSpinner(false);
      // enabling button
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 4000);
    } else {
      const couponCodePromise = new Promise(async (resolve, reject) => {
        try {
          const response = await couponCodeApi(coupon_api_payload);
          // console.log("couponCode api response data : ", response.data);
          resolve(response.data);
        } catch (error) {
          reject(error);
        }
      });

      couponCodePromise
        .then((data) => {
          const { success, message, couponError } = data;
          let {savings,finalAveragePerDayRent,total,couponPercentage} =data.data.summary;
          console.log("ssdssdsdssdds=>",data.data.summary);
          // setShowSpinner(false);
          if (couponError !== null) {
            setShowSpinner(false);
            alert.error(couponError);
            setTimeout(() => {
              setIsButtonDisabled(false); 
            }, 4000);
            return; 
          }
          setTimeout(() => {
            setShowSpinner(false);

            /* Applying discount */

            if (success) {
              setFinalAveragePerDayRent(finalAveragePerDayRent);
              setTotal(total);
              setSaving(savings);
              // const discountedPricePerDay = Math.floor(
              //   pricePerDay - pricePerDay * (percentage / 100)
              // );
              // setPricePerDay(discountedPricePerDay);
              // const FinalCost = numberOfRooms * daysGap * pricePerDay;
              // setTotalCost(
              //   numberOfRooms * daysGap * discountedPricePerDay + priceExtraBed
              // );
              // const updatedTotalCost = Math.floor(
              //   FinalCost - FinalCost * (percentage / 100)
              // );
              // console.log("savingssss", saving, FinalCost, updatedTotalCost);
              // if (saving) {
              //   setSaving(saving + (FinalCost - updatedTotalCost));
              // } else {
              //   setSaving(FinalCost - updatedTotalCost);
              // }

              // console.log("asdgy2 saving ==> ", saving);
              // saving = totalCost1 - updatedTotalCost;
              // console.log("Saving", saving);
              // totalCost2 = updatedTotalCost;
              // console.log("jasdhjsahd", totalCost2);
              localStorage.setItem('couponDetails', JSON.stringify({
                isCouponApplied: true,
                finalAveragePerDayRent,
                total,
                savings,
                couponPercentage,
                couponCode: couponData.couponCode,
              }));
              setCouponApplied(true);
              setDiscount(couponPercentage);
              setIsButtonDisabled(false);
              alert.success(`You've successfully applied a ${couponPercentage} discount to your booking`);
              couponCodeInputRef.current.focus();
            } else {
              handleResetForm();
              // alert.error(message);
            }
            // enabling button
            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 4000);
          }, 1000);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(
            "Error occurred while verifying coupon with server",
            error
          );
          alert.error("Oops! Something unexpected happened.");
          // enabling button
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 4000);
        });
    }
  };

  const couponRemovedAPI = (e) => {
    setShowSpinner(true);
    setIsButtonDisabled(true);

    if (!coupon_api_payload.couponCode) {
      // console.log("Coupon Payload is empty in Booking Page >>>");
      setShowSpinner(false);
    } else {
      const removedCouponCodePromise = new Promise(async (resolve, reject) => {
        try {
          const response = await removeCouponApi(coupon_api_payload);
          // console.log("couponCode api response data : ", response.data);
          resolve(response.data);
        } catch (error) {
          reject(error);
        }
      });

      removedCouponCodePromise
        .then((data) => {
          const { success, message } = data;
          // setShowSpinner(false);

          setTimeout(() => {
            setShowSpinner(false);

            /* Applying discount */

            if (success) {
              // getDiscount_Status();
              // console.log(
              //   "### Coupon Removed & usage count updated in server ###"
              // );
            } else {
              // console.log("### Coupon Removed API Failure Response ###");
            }
            // enabling button
            setTimeout(() => {
              setIsButtonDisabled(false);
            }, 4000);
          }, 1000);
        })
        .catch((error) => {
          setShowSpinner(false);
          console.error(
            "Error occurred while removing coupon from server:",
            error
          );
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 4000);
        });
    }
  };

  const readOnlyMailAlertMsg = () => {
    setIsButtonDisabled(true);
    alert.error("The email field is not editable.");
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 4000);
  };

  const handleCouponRemoved = () => {
    setShowSpinner(true);
    setPricePerDay(PerDayCost);
    couponRemovedAPI(); // remove coupon api call
    setCouponApplied(false);
    setFinalAveragePerDayRent(null);
    setSaving(null);
    setDiscount(null);
    setTotal(null);
    localStorage.removeItem('couponDetails');
    setTimeout(() => {
      setShowSpinner(false);
      setIsButtonDisabled(false);
      // setCouponRemoved(true);
      // saving = 0;
      // setTotalCost(totalCost1);
      // setSaving(0);
      // totalCost2 = totalCost1;
      // console.log("SHdassjareset ===", totalCost2);
      // setDiscount(0);
      handleResetForm();
      alert.success("Applied discount coupon removed!");
    }, 1000);
  };

  const handleClickDone = () => {
    setPaymentSummaryDataFunc({});
    // setBookingSummaryDataFunc({});
    navigate(`/`);
  };

  const hotelPaymentDetails = () => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
      setShowPaymentDetails(true);
    }, 1000);
  };

  const orderUrl = `${BASE_URL}/payment/order/${Booking_id}`;
  // console.log("ORDER URL", orderUrl);

  /* Updated */
  const paymentHandler = () => {
    // e.preventDefault();
    setShowSpinner(true);
    // disabling button
    setIsButtonDisabled(true);

    const orderPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(orderUrl);
        const data = response.data;
        // console.log(
        //   "Order Data Received from server ===> : " + JSON.stringify(data)
        // );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

    orderPromise
      .then((data) => {
        const { success } = data;

        setTimeout(() => {
          setShowSpinner(false);
          if (success) {
            const options = {
              key: config.RAZOR_PAY_KEY_ID,
              name: "Sengal Boutique Hotel",
              description: "Greetings from Sengal Boutique Hotel",
              image:
                "https://sengalboutiquehotel.com/static/media/logo_sengal.af4fe7c2720c23632db1.png",
              order_id: data.order.id,

              handler: (response) => {
                // console.log(
                //   "Razorpay Payment Object Received in handler ===> ",
                //   response
                // );

                const paymentId = response.razorpay_payment_id;
                const url = `${BASE_URL}/payment/capture/${paymentId}`;

                const capture_api_payload = {
                  amount: data?.order?.amount,
                  currency: data?.order?.currency,
                  Booking_id: Booking_id,
                };

                setShowSpinner(true);
                const capturePromise = new Promise(async (resolve, reject) => {
                  try {
                    const captureResponse = await axios.post(
                      url,
                      capture_api_payload
                    );
                    // console.log(
                    //   "Capture Payment Api Data Received from server ===> : ",
                    //   captureResponse.data
                    // );
                    resolve(captureResponse.data);
                  } catch (error) {
                    reject(error);
                  }
                });

                capturePromise
                  .then((captureData) => {
                    // console.log("Capture api data", captureData);
                    const { success, message } = captureData;
                    // console.log(
                    //   "Payment Status: " + captureData.success,
                    //   captureData.message
                    // );
                    setTimeout(() => {
                      setShowSpinner(false);
                      if (success) {
                        setPaid(true);
                        // console.log("Payment has done : ", paid);
                      } else {
                        // fail response handling
                        // console.log("Payment has not been done : ", paid);
                        // console.log("response msg:", message);
                        alert.error(message);
                      }
                      // enabling button
                      setTimeout(() => {
                        setIsButtonDisabled(false);
                      }, 4000);
                    }, 1000);
                  })
                  .catch((err) => {
                    console.log(
                      "Something went wrong while capturing payment ===> : ",
                      err
                    );
                    alert.error(
                      "Something went wrong while capturing payment."
                    );
                  });
              },

              theme: {
                // color: "#5c2222",
                // color: "rgba(1, 2, 0, 0.7)",
                color: "#191717",
              },
            };

            const rzp1 = new window.Razorpay(options);
            // rzp1.open();
            rzp1.open({
              modal: {
                // Open the payment gateway in a new window/tab
                target: "_blank",
              },
            });
          } else {
            // console.log("Oder API failure response: ", data.success);
          }
          // enabling button
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 4000);
        }, 1000);
      })
      .catch((err) => {
        setShowSpinner(false);
        console.log("Something went wrong while creating order ===> : ", err);
        alert.error("Something went wrong while creating order.");
        // enabling button
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 4000);
      });
  };

  // if (Booking_record_stored) {
  //   // paymentHandler(); // open payment portal
  // }

  useEffect(() => {
    if (Booking_record_stored) {
      paymentHandler(); // open payment portal
      setBooking_record_stored(false);
    }
  }, [Booking_record_stored]);

  const discountPopUpToast = Array.isArray(discountDescriptions) && discountDescriptions.length > 0 && discountDescriptions[0]
  ? (discountDescriptions[0].split(",").length > 1
      ? "Multiple discount applied (subject to eligibility): "
      : "Discount ") +
    discountDescriptions[0]
      .split(",")
      .map(desc => desc.trim())
      .reduce((acc, desc, index, arr) => {
        if (arr.length === 1) {
          // For a single element, no comma or "and"
          acc += desc;
        } else if (index === arr.length - 1) {
          // For the last element, prepend "and" without a comma
          acc += " and " + desc;
        } else {
          // For all other elements, add a comma
          acc += desc + (index === arr.length - 2 ? "" : ", ");
        }
        return acc;
      }, "")
  : "";


  /**
   * @param null
   * @description Checking discount available status from server and set the current status
   * @returns null
   */
  // const getDiscount_Status = async () => {
  //   try {
  //     const response = await get_Discount_Availablity_Status();
  //     if (response.success) {
  //       setDiscount_Details({
  //         percentage: response.percentage,
  //         discount_description: response?.discount_description,
  //         startDate: response.startDate,
  //         endDate: response.endDate,
  //       });
  //       setPricePerDay((updated_price) => {
  //         const discountedPricePerDay = Math.floor(
  //           updated_price - updated_price * (response.percentage / 100)
  //         );
  //         console.log("dsfsdfsdffddfsdf =>",discountedPricePerDay);
  //         setPricePerDay(discountedPricePerDay);
  //         const FinalCost = numberOfRooms * daysGap * updated_price;
  //         // setTotalCost(FinalCost + priceExtraBed);
  //         setTotalCost(
  //           numberOfRooms * daysGap * discountedPricePerDay + priceExtraBed
  //         );
  //         const updatedTotalCost = Math.floor(
  //           FinalCost - FinalCost * (response.percentage / 100)
  //         );
  //         setSaving(FinalCost - updatedTotalCost);
  //         // console.error("Getting discount", FinalCost + priceExtraBed);
  //         return discountedPricePerDay;
  //       });
  //     } else {
  //       setSaving(0);
  //     }
  //   } catch (error) {
  //     console.log("Error in check availability discount status");
  //   }
  // };

  /* payment confirmation message */
  if (paid) {
    return (
      <div>
        <Parallax
          // blur={10}
          bgImage={IMAGES.bgImg_section1}
          bgImageAlt="section1"
          // strength={120}
        >
          {isSmallScreen ? (
            // Render specific content or behavior for small screens
            <div className={styles1.content1}>
              <div className={styles1.payment_details_cont1}>
                <div className={styles1.payment_details_cont_head1}></div>
                <div className={styles1.payment_details_cont_body1}>
                  <div className={styles1.payment_details_cont_form_msg}>
                    {/* BOOKING CONFIRMED CONTAIN ARE HERE */}
                    <div className={styles1.center_tick}>
                      <BsCheck2All size={27.5} color="white" />
                    </div>
                    <div className={styles1.msg_title}>
                      Thank you for your booking!
                    </div>
                    <div className={styles1.msg_content}>
                      Booking confirmation has been sent to your email.
                    </div>
                  </div>
                </div>
                {/* foot */}
                <div className={styles1.payment_details_cont_foot}>
                  {/* <Link to="/"> */}
                  <button
                    type="submit"
                    className={styles1.btn_pay1}
                    onClick={handleClickDone}
                  >
                    <div className={styles1.btn_pay_cont}>
                      <div className={styles1.btn_name}>Done</div>
                    </div>
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          ) : (
            // Render content for larger screens
            <div className={styles1.content}>
              <div className={styles1.payment_details_cont}>
                <div className={styles1.payment_details_cont_head}></div>
                <div className={styles1.payment_details_cont_body1}>
                  <div className={styles1.payment_details_cont_form_msg}>
                    {/* BOOKING CONFIRMED CONTAIN ARE HERE */}
                    <div className={styles1.center_tick}>
                      <BsCheck2All size={27.5} color="white" />
                    </div>
                    <div className={styles1.msg_title}>
                      Thank you for your booking!
                    </div>
                    <div className={styles1.msg_content}>
                      Booking confirmation has been sent to your email.
                    </div>
                  </div>
                </div>
                {/* foot */}
                <div className={styles1.payment_details_cont_foot}>
                  {/* <Link to="/"> */}
                  <button
                    type="submit"
                    className={styles1.btn_pay1}
                    onClick={handleClickDone}
                  >
                    <div className={styles1.btn_pay_cont}>
                      <div className={styles1.btn_name}>Done</div>
                    </div>
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          )}
        </Parallax>
      </div>
    );
  }

  /* message */
  if (showPaymentDetails) {
    return (
      <div>
        <Parallax
          // blur={10}
          bgImage={IMAGES.bgImg_section1}
          bgImageAlt="section1"
          // strength={120}
        >
          {isSmallScreen && isLandscape === false ? (
            // Render specific content or behavior for small screens
            <div className={styles1.content1}>
              <div className={styles1.payment_details_cont1}>
                <div className={styles1.payment_details_cont_head1}></div>
                <div className={styles1.payment_details_cont_body1}>
                  <div className={styles1.payment_details_cont_form_msg}>
                    {/* BOOKING CONFIRMED CONTAIN ARE HERE */}
                    <div className={styles1.center_tick}>
                      <BsCheck2All size={27.5} color="white" />
                    </div>
                    {/* <div className={styles.msg_title}>
                      Thank you for your booking!
                    </div> */}
                    {/* <div className={styles.msg_content}>
                      Booking confirmation has been sent to your email.
                    </div> */}
                    <div className={styles1.msg_title}>
                      Thank you for choosing Sengal Boutique Hotel!
                    </div>
                    <div className={styles1.msg_content}>
                      {/* Booking confirmation has been sent to your email. */}
                      Please contact with hotel admin to get your room booking
                      payment details.
                    </div>
                    <div className={styles1.msg_content_small}>
                      {/* Booking confirmation has been sent to your email. */}
                      <span>Please reach out to us at </span>
                      <span style={{ color: "#FFB000" }}>
                        (+91) 93848 51008
                      </span>{" "}
                      <span>for confirming your room.</span>
                    </div>
                    <div className={styles1.msg_content_note}>
                      {/* Booking confirmation has been sent to your email. */}
                      <i>
                        <small>
                          To guarantee the reservation, please call our staff at
                          the earliest.
                        </small>
                      </i>
                    </div>
                  </div>
                </div>
                {/* foot */}
                <div className={styles1.payment_details_cont_foot}>
                  {/* <Link to="/"> */}
                  <button
                    type="submit"
                    className={styles1.btn_pay1}
                    onClick={handleClickDone}
                  >
                    <div className={styles1.btn_pay_cont}>
                      <div className={styles1.btn_name}>Done</div>
                    </div>
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          ) : (
            // Render content for larger screens
            <div className={styles1.content}>
              <div className={styles1.payment_details_cont}>
                <div className={styles1.payment_details_cont_head}></div>
                <div className={styles1.payment_details_cont_body1}>
                  <div className={styles1.payment_details_cont_form_msg}>
                    {/* BOOKING CONFIRMED CONTAIN ARE HERE */}

                    {isLandscape ? (
                      <></>
                    ) : (
                      <div className={styles1.center_tick}>
                        <BsCheck2All size={27.5} color="white" />
                      </div>
                    )}

                    <div className={styles1.msg_title}>
                      Thank you for choosing Sengal Boutique Hotel for your
                      upcoming stay!
                    </div>
                    <div className={styles1.msg_content}>
                      {/* Booking confirmation has been sent to your email. */}
                      We look forward to providing you with a comfortable and
                      unique experience.
                    </div>
                    <div className={styles1.msg_content}>
                      {/* Booking confirmation has been sent to your email. */}
                      Please reach out to us at{" "}
                      <span style={{ color: "#FFB000" }}>
                        (+91) 93848 51008
                      </span>{" "}
                      for confirming your room.
                    </div>
                    <div className={styles1.msg_content_note}>
                      {/* Booking confirmation has been sent to your email. */}
                      <i>
                        <small>
                          To guarantee the reservation, please call our staff at
                          the earliest.
                        </small>
                      </i>
                    </div>
                  </div>
                </div>
                {/* foot */}
                <div className={styles1.payment_details_cont_foot}>
                  {/* <Link to="/"> */}
                  <button
                    type="submit"
                    className={styles1.btn_pay1}
                    onClick={handleClickDone}
                  >
                    <div className={styles1.btn_pay_cont}>
                      <div className={styles1.btn_name}>Done</div>
                    </div>
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          )}
        </Parallax>
      </div>
    );
  }
  return (
    <div>
      <Parallax
        // blur={10}
        bgImage={IMAGES.bgImg_section1}
        bgImageAlt="section1"
        // strength={120}
      >
        <div className={styles.content}>
          {/* active spinner */}
          {showSpinner && (
            <div className={styles.spinner_layout}>
              <SpinnerDotted color="white" />
            </div>
          )}

          {isSmallScreen ? (
            <></>
          ) : (
            <HeaderWithIcon HomeIconClick={handleHomeClick} />
          )}
          <form onSubmit={handleSubmit}>
            <div className={styles.booking_details_cont}>
              {/* head */}
              <div className={styles.booking_details_cont_head}>
                <div className={styles.head_left}>Booking Summary</div>
                {isSmallScreen && isLandscape === false ? (
                  // Render specific content or behavior for small screens
                  <>
                    {discountDescriptions && discountDescriptions.length > 0 && discountDescriptions[0] !== null && (
                     <div className={styles.head_center}>
                      <span style={{marginLeft:'5px'}}>
                      <img
                            src={promoCode_logo1}
                            className={styles.promocode_icon}
                            alt="Coupon Icon"
                          />
                        </span>
                        &nbsp;
                        <div className={styles.descriptionPopMobile}>
                        {discountPopUpToast}
                        </div>
                      </div>
                    )}
                      <div className={styles.head_right}>
                        <div>Check-in time : 3:00 pm</div>
                        <div>Check-out time : 11:00 am</div>
                      </div>
                  </>
                ) : (
                  <>
                     {discountDescriptions && discountDescriptions.length > 0 && discountDescriptions[0] !== null && <div className={styles.head_center}>
                        <span>
                          {" "}
                          <img
                            src={promoCode_logo1}
                            className={styles.promocode_icon}
                            alt="Coupon Icon"
                          />
                        </span>
                      <div className={styles.descriptionPopUp}>
                       {discountPopUpToast}
                      </div>
                     </div>}
                    <div className={styles.head_right}>
                      Check-in time : 3:00 pm, Check-out time : 11:00 am
                    </div>
                  </>
                )}
                {/* <div className={styles.head_right}>
                  Check-in time: 3:00 pm, Check-out time: 11:00 am
                </div> */}
                {/* <div>Total: {totalCost}</div> */}
              </div>
              {/* Booking Details */}
              <div className={styles.booking_details_cont_body}>
                <div className={styles.above_pay_msg}>
                  <div className={styles.booking_details_cont_form}>
                    {/* Order Summary Details */}
                    <div className={styles.abc}>
                      <div className={styles.img_cont}>
                        <img
                          src={book_hotel_img}
                          className={styles.book_room_img}
                          alt="confirm_tick"
                        />
                      </div>
                      <div className={styles.room_name_cont}>Deluxe Room</div>
                    </div>
                    <div className={styles.def}>
                      {/* right part */}

                      {isSmallScreen && isLandscape === false ? (
                        <div className={styles.abc1}>
                          {loggedIn_user_mail_status ? (
                            <div className={styles.abc1_info_fields}>
                              <div className={styles.user_input}>
                                <div className={styles.input_field_cont}>
                                  <input
                                    type="text"
                                    placeholder="Name"
                                    className={styles.input_field1}
                                    name="guestName"
                                    value={guestData.guestName}
                                    onChange={handleChange}
                                    required
                                    autoComplete="off"
                                    maxLength="26"
                                    minLength="3"
                                    // pattern="[a-zA-Z]+( [a-zA-Z]+)?\s*"
                                    // pattern="[a-zA-Z]+( [a-zA-Z]+)*"
                                    pattern="[A-Za-z ]{1,}"
                                    ref={InputRef}
                                  />
                                </div>
                                <div className={styles.input_field_cont}>
                                  <input
                                    type="tel"
                                    placeholder="Mobile"
                                    className={styles.input_field2}
                                    name="guestMobile"
                                    value={guestData.guestMobile}
                                    onChange={handleChange}
                                    required
                                    autoComplete="off"
                                    maxLength="10"
                                    onInput={(e) => {
                                      const input = e.target.value;
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      e.target.value = numericInput;
                                    }}
                                    ref={InputRef}
                                  />
                                </div>
                                <div
                                  className={styles.input_field_cont}
                                  onClick={readOnlyMailAlertMsg}
                                >
                                  <input
                                    type="email"
                                    placeholder="Email"
                                    className={styles.input_field3}
                                    name="guestEmail"
                                    value={guestData.guestEmail}
                                    onChange={handleChange}
                                    required
                                    autoComplete="off"
                                    maxLength="30"
                                    ref={InputRef}
                                    readOnly
                                    disabled={isButtonDisabled}
                                  />
                                </div>
                              </div>
                              <div className={styles.complimentry_bf_cont}>
                                {/* <div>Complimentary Food</div>
                                <div className={styles.complimentry_checkbox}>
                                  <input
                                    type="checkbox"
                                    checked={isComplimentry}
                                    onChange={handleComplimentryChange}
                                    value={isComplimentry}
                                    name="complimentaryBreakfast"
                                    className={styles.checkbox_complimentry}
                                  />
                                </div> */}
                                {STRINGS.complementarybreakfast}
                              </div>
                              {/* <div className={styles.abc1_extra_bed}>
                              <div className={styles.extbd_titl}>
                                Extra Bed :{" "}
                              </div>
                              <Dropdown
                                className={styles.select_options_list}
                                options={extraBedOptions}
                                value={guestData.numberOfExtraBeds}
                                onChange={(selectedOption) =>
                                  handleChange({
                                    target: {
                                      name: "numberOfExtraBeds",
                                      value: selectedOption.value,
                                    },
                                  })
                                }
                                placeholder="Number of extra beds"
                                required
                              />
                            </div> */}
                            </div>
                          ) : (
                            <div className={styles.complimentry_bf_cont1}>
                              <div>
                                Please{" "}
                                <Link
                                  to="/user/auth/login"
                                  className={styles.auth_txt_link}
                                >
                                  <span
                                    className={styles.auth_txt}
                                    onClick={setBookFunc(true)}
                                  >
                                    sign in
                                  </span>
                                </Link>{" "}
                                or{" "}
                                <Link
                                  to="/user/auth/register"
                                  className={styles.auth_txt_link}
                                >
                                  <span
                                    className={styles.auth_txt}
                                    onClick={setBookFunc(true)}
                                  >
                                    sign up
                                  </span>
                                </Link>{" "}
                                in order to proceed with the booking process.
                              </div>
                              <div
                                className={styles.complimentry_bf_cont}
                                style={{ marginTop: "20px" }}
                              >
                                {STRINGS.complementarybreakfast}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className={styles.abc1}>
                          {loggedIn_user_mail_status ? (
                            <div className={styles.abc1_info_fields}>
                              <div className={styles.user_input}>
                                <div className={styles.input_field_cont}>
                                  <input
                                    type="text"
                                    placeholder="Name"
                                    className={styles.input_field1}
                                    name="guestName"
                                    value={guestData.guestName}
                                    onChange={handleChange}
                                    required
                                    maxLength="26"
                                    minLength="3"
                                    autoComplete="off"
                                    // pattern="[a-zA-Z]+( [a-zA-Z]+)?\s*"
                                    // pattern="[a-zA-Z]+( [a-zA-Z]+)*"
                                    pattern="[A-Za-z ]{1,}"
                                    ref={InputRef}
                                  />
                                </div>
                                <div className={styles.input_field_cont}>
                                  <input
                                    type="tel"
                                    placeholder="Mobile"
                                    className={styles.input_field2}
                                    name="guestMobile"
                                    value={guestData.guestMobile}
                                    onChange={handleChange}
                                    required
                                    autoComplete="off"
                                    maxLength="10"
                                    onInput={(e) => {
                                      const input = e.target.value;
                                      const numericInput = input.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      e.target.value = numericInput;
                                    }}
                                    ref={InputRef}
                                  />
                                </div>
                                <div
                                  className={styles.input_field_cont}
                                  onClick={readOnlyMailAlertMsg}
                                >
                                  <input
                                    type="email"
                                    placeholder="Email"
                                    className={styles.input_field3}
                                    name="guestEmail"
                                    value={guestData.guestEmail}
                                    onChange={handleChange}
                                    required
                                    autoComplete="off"
                                    maxLength="30"
                                    ref={InputRef}
                                    readOnly
                                    disabled={isButtonDisabled}
                                  />
                                </div>
                              </div>
                              <div className={styles.complimentry_bf_cont}>
                                {/* <div>Complimentary Food</div>
                                <div className={styles.complimentry_checkbox}>
                                  <input
                                    type="checkbox"
                                    checked={isComplimentry}
                                    onChange={handleComplimentryChange}
                                    value={isComplimentry}
                                    name="complimentaryBreakfast"
                                    className={styles.checkbox_complimentry}
                                  />
                                </div> */}
                                {STRINGS.complementarybreakfast}
                              </div>
                              {/* <div className={styles.abc1_extra_bed}>
                              <div className={styles.extbd_titl}>
                                Extra Bed :{" "}
                              </div>
                              <Dropdown
                                className={styles.select_options_list}
                                options={extraBedOptions}
                                value={guestData.numberOfExtraBeds}
                                onChange={(selectedOption) =>
                                  handleChange({
                                    target: {
                                      name: "numberOfExtraBeds",
                                      value: selectedOption.value,
                                    },
                                  })
                                }
                                placeholder="Number of extra beds"
                                required
                              />
                            </div> */}
                            </div>
                          ) : (
                            <div className={styles.complimentry_bf_cont1}>
                              <div>
                                Please{" "}
                                <Link
                                  to="/user/auth/login"
                                  className={styles.auth_txt_link}
                                >
                                  <span
                                    className={styles.auth_txt}
                                    onClick={setBookFunc(true)}
                                  >
                                    sign in
                                  </span>
                                </Link>{" "}
                                or{" "}
                                <Link
                                  to="/user/auth/register"
                                  className={styles.auth_txt_link}
                                >
                                  <span
                                    className={styles.auth_txt}
                                    onClick={setBookFunc(true)}
                                  >
                                    sign up
                                  </span>
                                </Link>{" "}
                                in order to proceed with the booking process.
                              </div>
                              <div
                                className={styles.complimentry_bf_cont}
                                style={{ marginTop: "20px" }}
                              >
                                {STRINGS.complementarybreakfast}
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {isSmallScreen && isLandscape === false ? (
                        // Render specific content or behavior for small screens
                        <div className={styles.def2}>
                          <div className={styles.data_cont_holder1}>
                            <div className={styles.data_cont1}>
                              <div className={styles.data_top}>
                                Check-in date
                              </div>
                              <div className={styles.data_bottom}>
                                {checkInDate}
                              </div>
                            </div>
                            <div className={styles.data_cont2}>
                              <div className={styles.data_top}>
                                Check-out date
                              </div>
                              <div className={styles.data_bottom}>
                                {checkOutDate}
                              </div>
                            </div>
                          </div>
                          <div className={styles.data_cont_holder2}>
                            <div className={styles.data_cont3}>
                              <div className={styles.data_top}>Rooms</div>
                              <div className={styles.data_bottom}>
                                {numberOfRooms}
                              </div>
                            </div>
                            {/* <div className={styles.data_cont4}>
                            <div className={styles.data_top}>Occupancy</div>
                            <div className={styles.data_bottom}>
                              {numberOfMembers}
                            </div>
                          </div> */}
                            <div className={styles.data_cont4}>
                              <div className={styles.data_top}>Adults</div>
                              <div className={styles.data_bottom}>
                                {numberOfAdults}
                              </div>
                            </div>
                            <div className={styles.data_cont5}>
                              <div className={styles.data_top}>Kids</div>
                              <div className={styles.data_bottom}>
                                {numberOfKids}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        // Render content for larger screens
                        <div className={styles.def2}>
                          <div className={styles.data_cont1}>
                            <div className={styles.data_top}>Check-in date</div>
                            <div className={styles.data_bottom}>
                              {checkInDate}
                            </div>
                          </div>
                          <div className={styles.data_cont2}>
                            <div className={styles.data_top}>
                              Check-out date
                            </div>
                            <div className={styles.data_bottom}>
                              {checkOutDate}
                            </div>
                          </div>
                          <div className={styles.data_cont3}>
                            <div className={styles.data_top}>Rooms</div>
                            <div className={styles.data_bottom}>
                              {numberOfRooms}
                            </div>
                          </div>
                          {/* <div className={styles.data_cont4}>
                          <div className={styles.data_top}>Occupancy</div>
                          <div className={styles.data_bottom}>
                            {numberOfMembers}
                          </div>
                        </div> */}
                          <div className={styles.data_cont4}>
                            <div className={styles.data_top}>Adults</div>
                            <div className={styles.data_bottom}>
                              {numberOfAdults}
                            </div>
                          </div>
                          <div className={styles.data_cont5}>
                            <div className={styles.data_top}>Kids</div>
                            <div className={styles.data_bottom}>
                              {numberOfKids}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* total side part */}
                  <div className={styles.booking_details_cont_form1}>
                    <div className={styles.total_upper_part}>
                      <div className={styles.total_upper_part_left}>
                        <div className={styles.total_list}>
                          {/* {discountDescriptions && discountDescriptions.length > 0 && discountDescriptions[0] !== null ?"Per Day Rent":"Avg Per Day Rent"} */}
                          Per Day Rent
                          </div>
                        <div className={styles.total_list}>Extra Bed Cost</div>
                        <div className={styles.total_list}>Sub Total</div>
                        <div className={styles.total_list}>Savings</div>
                        <div className={styles.total_list}>GST</div>
                        {/* <div className={styles.total_list}>Gift Card</div> */}
                      </div>
                      {!isCouponApplied ? (
                        <div className={styles.total_upper_part_right}>
                          {/* <div className={styles.total_list_amount}>
                         {daysGap}x{pricePerDay}
                         </div> */}
                          <div className={styles.total_list_amount}>
                            <span
                              className={styles.cut_out_subtotal}
                              style={{
                                // textDecoration: "line-through",
                                textDecoration: discountDescriptions && discountDescriptions.length > 0 && discountDescriptions[0] !== null ? "line-through" : "none",
                                paddingRight: "3px",
                                
                              }}
                            >
                              ₹ {PerDayCost}
                            </span>{" "}
                            {discountDescriptions && discountDescriptions.length > 0 && discountDescriptions[0] !== null && <span style={{paddingLeft:'5px'}}> ₹ {discountedPrice}</span>}
                          </div>

                          <div className={styles.total_list_amount}>
                            ₹ {priceExtraBed}
                          </div>
                          <div className={styles.total_list_amount}>
                            {" "}
                            {/* ₹ {subTotal} */}
                            ₹ {extraBedCost}
                          </div>
                          <div className={styles.total_list_amount}>
                            {/* ₹ {saving} */}
                            ₹ {SavingsCost}
                          </div>
                          <div className={styles.total_list_amount}>
                            ₹ {gstAmount}
                          </div>
                          {/* <div className={styles.total_list_amount}>{giftCard}</div> */}
                        </div>
                      ) 
                      : 
                      (
                        <div className={styles.total_upper_part_right}>
                          {/* <div className={styles.total_list_amount}>
                          {daysGap}x{pricePerDay}
                         </div> */}
                          <div className={styles.total_list_amount}>
                            <span
                              className={styles.cut_out_subtotal}
                              style={
                                isCouponApplied
                                  ? {
                                      textDecoration: "line-through",
                                    }
                                  : {
                                      textDecoration: "none",
                                    }
                              }
                            >
                              ₹ {PerDayCost}
                            </span>{" "}
                            {/* <span>{finalAveragePerDay}</span> */}
                            <span style={{paddingLeft:'5px'}}> ₹ {finalAveragePerDay}</span>
                          </div>

                          <div className={styles.total_list_amount}>
                            ₹ {priceExtraBed}
                          </div>
                          <div className={styles.total_list_amount}>
                            {" "}
                            ₹ {extraBedCoupon}
                          </div>
                          <div className={styles.total_list_amount}>
                            ₹ {saving}
                          </div>
                          <div className={styles.total_list_amount}>
                            ₹ {gstAmountCoupon}
                          </div>
                          {/* <div className={styles.total_list_amount}>{giftCard}</div> */}
                        </div>
                      )}
                    </div>
                    {isCouponApplied ? (
                      <div className={styles.total_middle_part}>
                        <div className={styles.coupon_applied_sec2}>
                          <div className={styles.coupon_msg}>
                            <span>
                              <img
                                src={promoCode_logo}
                                className={styles.promocode_icon}
                                alt="Coupon Icon"
                              />
                            </span>
                            <div>
                              {" "}
                              <div>
                                {discount} {STRINGS.coupon_applied_msg}
                              </div>
                              <div
                                className={styles.remove_coupon}
                                onClick={handleCouponRemoved}
                              >
                                {STRINGS.coupon_Remove_msg}!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.total_middle_part}>
                        <div className={styles.coupon_sec3}>
                          {STRINGS.ask_coupen_code}
                        </div>
                        <div className={styles.coupon_sec2}>
                          <input
                            type={"text"}
                            placeholder="xxxxxx"
                            className={styles.input_field1_coupon}
                            name="couponCode"
                            value={couponData.couponCode}
                            onChange={handleChange1}
                            maxLength="6"
                            autoComplete="off"
                            ref={couponCodeInputRef}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                // Handle the Enter key press as needed
                                handleSubmit1(e);
                              }
                            }}
                          />
                          <button
                            type="button"
                            className={styles.btn_submit_coupon}
                            onClick={handleSubmit1}
                            disabled={isButtonDisabled}
                          >
                            <div className={styles.btn_cont_coupon}>
                              <div className={styles.btn_name_coupon}>
                                Apply
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    )}

                    <div className={styles.total_lower_part}>
                      <div className={styles.total_amt}>
                      Total : ₹ {!isCouponApplied ? extraBedCost + gstAmount : extraBedCoupon + gstAmountCoupon}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.terms}>
                  <input
                    type="checkbox"
                    checked={termsChecked}
                    onChange={handleTermsChange}
                    className={styles.terms_checkbox}
                  />
                  {STRINGS.termsandconditionaccpet}
                  <div
                    onClick={openTermsPopup}
                    className={styles.accept_termcondition}
                  >
                    {STRINGS.terms_msg}
                  </div>
                </div>
                <div className={styles.pay_msg}>
                  {STRINGS.online_payment_query}
                </div>
                {showTermsPopup?.state && (
                  <TermsConditions
                    closeTermsPopup={closeTermsPopup}
                    isContent_Data={showTermsPopup?.isContent_Data}
                  />
                )}
              </div>
              {/* foot */}
              <div className={styles.booking_details_cont_foot}>
                {isSmallScreen ? (
                  <div className={styles.foot_content}>
                    <button
                      className={styles.btn_confirm1}
                      disabled={isButtonDisabled}
                    >
                      <div
                        className={styles.btn_cont1}
                        onClick={handleBookingCancel}
                      >
                        <div className={styles.btn_name}>Cancel</div>
                      </div>
                    </button>
                    <button
                      type="submit"
                      className={styles.btn_confirm}
                      // disabled={!loggedIn_user_mail_status}
                      // onClick={() => {
                      //   if (!loggedIn_user_mail_status) {
                      //     alert.error("Please sign in or sign up to continue.");
                      //   }
                      // }}
                      disabled={isButtonDisabled}
                    >
                      <div
                        className={
                          termsChecked
                            ? styles.btn_cont
                            : styles.btn_terms_check
                        }
                      >
                        <div className={styles.btn_name}>Pay Now</div>
                      </div>
                    </button>
                  </div>
                ) : (
                  <div className={styles.foot_content}>
                    <button
                      className={styles.btn_confirm1}
                      disabled={isButtonDisabled}
                    >
                      <div
                        className={styles.btn_cont1}
                        onClick={handleBookingCancel}
                      >
                        <div className={styles.btn_name}>Cancel</div>
                      </div>
                    </button>
                    <button
                      type="submit"
                      className={styles.btn_confirm}
                      // disabled={!loggedIn_user_mail_status}
                      // onClick={() => {
                      //   if (!loggedIn_user_mail_status) {
                      //     alert.error("Please sign in or sign up to continue.");
                      //   }
                      // }}
                      disabled={isButtonDisabled}
                    >
                      <div
                        className={
                          termsChecked
                            ? styles.btn_cont
                            : styles.btn_terms_check
                        }
                      >
                        <div className={styles.btn_name}>Pay Now</div>
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </Parallax>
    </div>
  );
}

export default BookingDetails;

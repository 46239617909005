import React, { useLayoutEffect, useState } from "react";
import styles from "../styles/policy_page.module.css";
import { STRINGS, TOOLBARCONFIG } from "../model/constant";
import { get_Policy_Status } from "../helper/http_api";
import Header_component from "../components/commoncomponents/header_component";
import NetworkError from "../components/commoncomponents/Network_Error";
import RichTextEditor, {
  getTextAlignClassName,
  getTextAlignStyles,
  getTextAlignBlockMetadata,
} from "react-rte";

const Policy_page = ({ Policy_Code }) => {
  const [showTermsPopup, setShowTermsPopup] = useState({
    state: false,
    isContent_Data: RichTextEditor.createEmptyValue(),
  });
  const [showSpinner, setShowSpinner] = useState(-1);

  useLayoutEffect(() => {
    getPolicy_Content(Policy_Code);
  }, []);

  /**
   * @param null
   * @description Get current content of the policy of terms and condition
   * @returns null
   */
  const getPolicy_Content = async (Policy_Code) => {
    try {
      const response = await get_Policy_Status(Policy_Code);
      if (response.success) {
        setShowTermsPopup({
          state: true,
          isContent_Data: RichTextEditor.createValueFromString(
            response?.responseContent?.policy_Content,
            "html",
            {
              customBlockFn: getTextAlignBlockMetadata,
            }
          ),
        });
      } else {
        // alert.error(STRINGS.somethingunexpected);
      }
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      console.log("Error in check policy status");
    }
  };

  const openInNewTab = (event) => {
    const target = event.target;
    if (event.target.closest("a")) {
      // Check if the clicked element is a link
      event.preventDefault(); // Prevent the default link behavior
      window.open(target.closest("a").href, "_blank"); // Open the link in a new tab
    }
  };

  return (
    <>
      {showTermsPopup.state ? (
        <>
          <div className={styles.termsHeader}>
            <div className={styles.centerView}>
              <div className={styles.headerlogo}>
                <Header_component />
              </div>
              <div className={styles.termsheading}>
                {Policy_Code == 1
                  ? STRINGS.terms_value
                  : Policy_Code == 2
                  ? STRINGS.privacypolicy
                  : STRINGS.refundpolicy}
              </div>
            </div>
          </div>
          <div onClick={openInNewTab} className={styles.termsBody}>
            {/* <div
              dangerouslySetInnerHTML={{
                __html: showTermsPopup?.isContent_Data,
              }}
            /> */}
            <RichTextEditor
              toolbarConfig={TOOLBARCONFIG}
              blockStyleFn={getTextAlignClassName}
              value={showTermsPopup?.isContent_Data}
              // placeholder={STRINGS.content_placeholder}
              toolbarStyle={{
                backgroundColor: "transparent",
                border: "none",
              }}
              rootStyle={{
                backgroundColor: "transparent",
                border: "none",
              }}
              editorStyle={{
                display: "flex",
                backgroundColor: "transparent",
                overflow: "hidden",
              }}
              readOnly={true}
            />
          </div>
        </>
      ) : showSpinner == false ? (
        <NetworkError callBack={window?.location?.reload} />
      ) : (
        <></>
      )}
    </>
  );
};

export default Policy_page;

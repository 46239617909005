// react router
import { Routes, Route, BrowserRouter } from "react-router-dom";
// pages
import Home from "../pages/home";
import BookingDetails from "../pages/booking_details";
import Login from "../pages/login";
import Register from "../pages/register";
import EmailVerify from "../pages/email_verify";
import ForgotPassword from "../pages/forgot_password";
import ForgotPasswordVerify from "../pages/forgot_password_verify";
import SetNewPassword from "../pages/set_new_password";
import BeveragesMenu from "../pages/beverages_menu";
import CustomerFeedbackForm from "../pages/customer_feedback_form";
// food page
import FoodPage from "../pages/food_page";
import Page_Not_Found from "../pages/pagenot_found";
import Cart_page from "../pages/cart_page";
import Policy_page from "../pages/policy_page";
import { POLICY_CODES_LIST } from "../model/constant";
import PageViewTracker from "../components/anayticscomponent/PageViewTracker";

const PageRoutes = () => {
  return (
    //   <HashRouter>
    <BrowserRouter>
    <PageViewTracker/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact_Us" element={<Home />} />
        <Route path="/user/booking_details" element={<BookingDetails />} />
        <Route path="/user/auth/login" element={<Login />} />
        <Route path="/user/auth/register" element={<Register />} />
        <Route path="/user/auth/mail_verify" element={<EmailVerify />} />
        <Route path="/user/auth/forgot_password" element={<ForgotPassword />} />
        <Route
          path="/user/auth/forgot_password_verify"
          element={<ForgotPasswordVerify />}
        />
        <Route
          path="/user/auth/forgot_password/set_new_password"
          element={<SetNewPassword />}
        />
        <Route path="/beverages_menu" element={<BeveragesMenu />} />
        <Route
          path="/customer_feed_back_form"
          element={<CustomerFeedbackForm />}
        />
        {/* SBH Food Page */}
        <Route path="/food_page" element={<FoodPage />} />
        <Route path="*" element={<Page_Not_Found />} />
        <Route path="/food_page/cart" element={<Cart_page />} />
        <Route
          path="/terms_condition"
          element={
            <Policy_page Policy_Code={POLICY_CODES_LIST.TERMS_AND_CONDITION} />
          }
        />
        <Route
          path="/privacy_policy"
          element={
            <Policy_page Policy_Code={POLICY_CODES_LIST.PRIVACY_POLICY} />
          }
        />
        <Route
          path="/refund_policy"
          element={
            <Policy_page Policy_Code={POLICY_CODES_LIST.REFUND_POLICY} />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default PageRoutes;

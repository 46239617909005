import React, { Component } from "react";

class Cancel_Svg extends Component {
  render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="27"
          height="27"
          rx="13.5"
          fill="black"
          fill-opacity="0.75"
        />
        <path
          d="M19.5 7L13.5 13.825M13.5 13.825L19.5 20M13.5 13.825L7.5 7M13.5 13.825L7.5 20"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    );
  }
}
export default Cancel_Svg;

import No_Internet from "../../assets/svg/Wifi_Svg";
import { STRINGS } from "../../model/constant";
import Styles from "../../styles/Network_Error.module.css";

const NetworkError = (props) => {
  return (
    <div className={Styles.networkError_Container}>
      <No_Internet />
      <div className={Styles.title_View}>Oops!</div>

      <div className={Styles.content_View}>
        {STRINGS.somethingunexpected} {STRINGS.network_error}
      </div>
      <div onClick={() => props.callBack()} className={Styles.button_View}>
        Try Again
      </div>
    </div>
  );
};
export default NetworkError;
